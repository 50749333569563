<template>
  <div class="Home">
    <div class="pcimg" v-if="isPc">
      <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/pc1.jpg" alt="" />
      <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/pc2.jpg" alt="" />
      <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/pc3.jpg" alt="" />
      <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/pc4.jpg" alt="" />
      <!-- <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/pc5.jpg" alt="" /> -->
    </div>
    <div class="iosimg" v-else>
      <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/ios1.jpg" alt="" />
      <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/ios2.jpg" alt="" />
      <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/ios3.jpg" alt="" />
      <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/ios4.jpg" alt="" />
      <!-- <img src="https://jnds-static.oss-cn-beijing.aliyuncs.com/ios5.jpg" alt="" /> -->
    </div>
    <div class="footer">
      <footerCard></footerCard>
    </div>
  </div>
</template>

<script>
import footerCard from "../../components/footerCard.vue";
export default {
  components: {
    footerCard,
  },
  data() {
    return {
      isPc: true,
    };
  },
  created() {
    if (document.body.clientWidth < 540) {
      this.isPc = false;
    }else{
      this.isPc = true;
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.Home {
  width: 100%;
  overflow: hidden;
  .footer{
    background: #f7faff;
  }
  img {
    width: 100%;
    margin-top: -5px;
  }
  .pcimg {
    display: block;
    overflow: hidden;
    background: url("../../assets/image/home-bg/background.png") no-repeat;
    background-size: 100% 100%;
  }
  .iosimg {
  }
}
</style>
